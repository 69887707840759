export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"hid":"og:image","property":"og:image","content":"https://cdn.realitystudio.ai/assets/images/og-image.png"},{"hid":"og:image:alt","property":"og:image:alt","content":"RealityStudio"},{"hid":"og:image:width","property":"og:image:width","content":"1200"},{"hid":"og:image:height","property":"og:image:height","content":"630"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"twitter:image","property":"twitter:image","content":"https://cdn.realitystudio.ai/assets/images/og-image.png"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:image:height","property":"twitter:image:height","content":"630"},{"hid":"twitter:image:width","property":"twitter:image:width","content":"1200"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp|Material+Symbols+Outlined&display=swap"},{"rel":"icon","href":"/favicon-reality-studio.ico"}],"style":[],"script":[],"noscript":[],"bodyAttrs":{"class":"bg-app-blue text-white"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false