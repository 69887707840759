export default defineNuxtPlugin(nuxtApp => {
	nuxtApp.provide('requestPushNotifications', async () => {
		const { user: user } = useUserStore()
		if (!user) {
			return
		}
		const { Capacitor } = await import('@capacitor/core')
		if (Capacitor.getPlatform() === 'web') return

		const { PushNotifications } = await import('@capacitor/push-notifications')
		PushNotifications.addListener('registrationError', (err: any) => {
			$sentryCaptureException(err.error)
		})

		PushNotifications.addListener('registration', async (token: any) => {
			try {
				await $api('/users/push-token', {
					method: 'POST',
					body: {
						token: token.value,
					},
				})
			} catch (error) {
				$sentryCaptureException(error)
			}
		})

		let permission = await PushNotifications.checkPermissions()

		if (permission.receive !== 'granted' || !user.firebaseToken) {
			permission = await PushNotifications.requestPermissions()

			if (permission.receive === 'granted') {
				PushNotifications.register()
			}
		}
	})

	nuxtApp.provide('addPushNotificationsListener', async () => {
		const { user: user } = useUserStore()

		if (!user) {
			return
		}

		const { Capacitor } = await import('@capacitor/core')
		if (Capacitor.getPlatform() === 'web') return

		const { PushNotifications } = await import('@capacitor/push-notifications')
		PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed: any) => {
			if (actionPerformed.actionId === 'tap' && actionPerformed.notification?.data?.url) {
				navigateTo(actionPerformed.notification?.data?.url)
			}
		})
	})
})
