export const useConfigStore = defineStore('config', {
	state() {
		return {
			displayFooter: true,
			pendingJobs: null as { model: boolean; prediction: boolean } | null,
			hasUnreadNotifications: false,
		}
	},
	actions: {
		setDisplayFooter(newValue: boolean) {
			this.displayFooter = newValue
		},
		setPendingJobs(newValue: any) {
			this.pendingJobs = newValue
		},
		setUnreadNotifications(newValue: any) {
			this.hasUnreadNotifications = newValue
		},
	},
})
