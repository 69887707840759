export default defineNuxtRouteMiddleware(async () => {
  const configStore = useConfigStore();
  const { isLoggedIn } = useUserStore();

  if (isLoggedIn) {
    const [pendingJobs, hasUnreadNotifications] = await Promise.all([
      useApi("/users/pending-jobs").then((res) => res.data),
      useApi("/notifications/has-unread").then((res) => res.data),
    ]);

    configStore.setPendingJobs(pendingJobs);
    configStore.setUnreadNotifications(hasUnreadNotifications);
  }
});
