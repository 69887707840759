import { h, render } from 'vue'
import Toast from '../components/default/Toast.vue'

export function removeElement(el: any) {
	const parentNode = el.parentNode

	if (parentNode && parentNode.remove) {
		el.parentNode?.remove()
	}
}

export function createComponent(component: any, props: any) {
	const vNode = h(component, props)
	const container = document.createElement('div')
	const parentContainer = document.querySelector('#toast-container')

	if (parentContainer) {
		parentContainer.appendChild(container)
		render(vNode, container)
	}

	return vNode.component
}

export default defineNuxtPlugin(nuxtApp => {
	nuxtApp.provide('toast', (options: any) => {
		if (process.server) {
			return
		}

		const defaultProps = {
			duration: 2000,
		}

		const propsData = Object.assign({}, defaultProps, options)
		createComponent(Toast, propsData)
	})
})
