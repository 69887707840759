<template>
  <div class="w-full animate-fade-up animate-duration-300" ref="root">
    <div class="rounded-xl" :class="toastBackgroundColor">
      <div class="flex items-center p-4">
        <div
          class="rounded-full p-[18px] flex items-center justify-center mr-2"
          :class="getIconBackground"
        >
          <span class="material-icons text-[32px]"> {{ icon }} </span>
        </div>
        <div>
          <p class="text-sm font-bold">{{ title }}</p>
          <p class="text-sm font-normal">{{ message }}</p>
        </div>
      </div>

      <div
        class="h-4 rounded-bl-xl w-1/2"
        :class="getTimerBackground"
        :style="{
          width: timerWidth,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { removeElement } from "~/plugins/toast";
import { render } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

const passedTime = ref(0);
let passedTimeInterval = null;
let closeTimeout = null;
const root = ref(null);

const close = () => {
  clearTimeout(closeTimeout);

  const wrapper = root.value;
  render(null, wrapper);
  removeElement(wrapper);
};

onMounted(() => {
  closeTimeout = setTimeout(close, props.duration);

  passedTimeInterval = setInterval(() => {
    passedTime.value += 10;
  }, 10);
});

onUnmounted(() => {
  clearInterval(passedTimeInterval);
});

const icon = computed(() => {
  switch (props.type) {
    case "success":
      return "check_circle";
    case "warning":
      return "warning";
    case "error":
      return "cancel";
  }
});

const toastBackgroundColor = computed(() => {
  const colorsByToastType = {
    success: "bg-green-800",
    warning: "bg-yellow-800",
    error: "bg-red-800",
  };
  return colorsByToastType[props.type];
});

const getIconBackground = computed(() => {
  const colorsByToastType = {
    success: "bg-green-700",
    warning: "bg-yellow-700",
    error: "bg-red-900",
  };
  return colorsByToastType[props.type];
});

const getTimerBackground = computed(() => {
  const colorsByToastType = {
    success: "bg-green-700",
    warning: "bg-yellow-700",
    error: "bg-red-900",
  };
  return colorsByToastType[props.type];
});

const timerWidth = computed(() => {
  const percent = (100 / props.duration) * passedTime.value;
  return `${percent}%`;
});
</script>
