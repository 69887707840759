import { Capacitor } from "@capacitor/core";

export default defineNuxtPlugin((nuxtApp) => {
  const locales = {
    en: "en-US",
    es: "es-ES",
    pt: "pt-BR",
  };

  nuxtApp.provide("getLocale", async () => {
    let languageLocale = "en";

    if (Capacitor.getPlatform() === "web") {
      const language = navigator.language;
      languageLocale = language?.split("-")[0];
    } else {
      const capacitorDevice = await import("@capacitor/device");
      languageLocale = await capacitorDevice.Device.getLanguageCode().then(
        (res) => res.value
      );
    }

    if (languageLocale in locales) {
      return locales[languageLocale as keyof typeof locales];
    }

    return "en-US";
  });
});
