import * as Sentry from '@sentry/vue'

const isDev = import.meta.env.DEV

export const sentryCaptureException = (error: any) => {
	if (!isDev && !import.meta.server) {
		if (typeof Sentry.setExtra === 'function') {
			Sentry.setExtra('error', JSON.stringify(error))
		}

		return Sentry.captureException(error)
	}

	console.error(error)
}

export default defineNuxtPlugin({
	name: 'sentry',
	parallel: true,
	async setup(nuxtApp) {
		if (import.meta.server) {
			return
		}

		const vueApp = nuxtApp.vueApp
		const config = useRuntimeConfig()

		Sentry.init({
			app: vueApp,
			dsn: config.public.sentryDsn,
			tracesSampleRate: 0,
		})

		nuxtApp.provide('sentryCaptureException', sentryCaptureException)
	},
})
