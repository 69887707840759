export default defineNuxtPlugin(() => {
	const userRequestHeaders = useRequestHeaders(['cookie'])
	const config = useRuntimeConfig()

	const api = $fetch.create<any>({
		baseURL: `${config.public.baseUrl}/api`,
		onRequest({ options }) {
			if (import.meta.server) {
				const headers = options.headers || {}

				options.headers = {
					...headers,
					...userRequestHeaders,
				}
			}
		},
	})

	return {
		provide: {
			api,
		},
	}
})
