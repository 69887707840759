import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/usr/src/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import motion_jmBwdqbgTJ from "/usr/src/app/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import api_GFfDXud5Cr from "/usr/src/app/plugins/api.ts";
import helpers_mgWmzbOuLB from "/usr/src/app/plugins/helpers.ts";
import i18n_VfGcjrvSkj from "/usr/src/app/plugins/i18n.ts";
import language_1MpgxzxhMS from "/usr/src/app/plugins/language.ts";
import papaparse_client_urBw0G3vZF from "/usr/src/app/plugins/papaparse.client.ts";
import posthog_client_fUucxKWhZE from "/usr/src/app/plugins/posthog.client.ts";
import pushNotifications_iXs70SwgBo from "/usr/src/app/plugins/pushNotifications.ts";
import sentry_3AyO8nEfhE from "/usr/src/app/plugins/sentry.ts";
import temp_B8vld1D3Eb from "/usr/src/app/plugins/temp.ts";
import toast_ysMjUcU7eJ from "/usr/src/app/plugins/toast.ts";
import twMerge_AFcdbGiGv8 from "/usr/src/app/plugins/twMerge.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  plugin_ghbUAjaD3n,
  motion_jmBwdqbgTJ,
  api_GFfDXud5Cr,
  helpers_mgWmzbOuLB,
  i18n_VfGcjrvSkj,
  language_1MpgxzxhMS,
  papaparse_client_urBw0G3vZF,
  posthog_client_fUucxKWhZE,
  pushNotifications_iXs70SwgBo,
  sentry_3AyO8nEfhE,
  temp_B8vld1D3Eb,
  toast_ysMjUcU7eJ,
  twMerge_AFcdbGiGv8
]