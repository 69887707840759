import { defineNuxtPlugin } from '#app'
import { Capacitor } from '@capacitor/core'
import { v4 } from 'uuid'

export default defineNuxtPlugin(nuxtApp => {
	const currencyByLang = {
		'en-US': 'USD',
		'pt-BR': 'BRL',
	}

	nuxtApp.provide('getPlatform', () => {
		return Capacitor.getPlatform()
	})

	nuxtApp.provide('formatCurrency', (value: any) => {
		const user = useUserStore().user
		const lang = user?.lang || 'en-US'

		const currency = currencyByLang[lang as keyof typeof currencyByLang]

		return new Intl.NumberFormat(lang, {
			style: 'currency',
			currency: currency || 'USD',
		}).format(value)
	})

	nuxtApp.provide('getWindow', () => {
		const { isMobile } = useDevice()
		let targetElement

		if (isMobile) {
			targetElement = document
		} else {
			targetElement = document.querySelector('#mockupWindow')
		}

		return targetElement
	})

	nuxtApp.provide('copyToClipboard', (text: string) => {
		if (!document) {
			return
		}

		const element = document.createElement('textarea')
		element.value = text
		element.setAttribute('readonly', '')
		element.style.position = 'absolute'
		element.style.left = '-9999px'
		document.body.appendChild(element)
		element.select()
		document.execCommand('copy')
		document.body.removeChild(element)
	})

	nuxtApp.provide('truncateText', (string: string, length = 0) => {
		if (string.length < length) {
			return string
		}

		return string.substring(0, length) + '...'
	})

	nuxtApp.provide('share', async (content: any) => {
		if (Capacitor.getPlatform() === 'web') {
			if (navigator && navigator.share) {
				navigator.share(content)
			}
		} else {
			const { Share } = await import('@capacitor/share')
			const canShare = await Share.canShare()
			if (!canShare) return
			await Share.share(content)
		}
	})

	nuxtApp.provide('formatDate', (date: any, format = 'DD/MM/YYYY [at] HH:mm') => {
		return useNuxtApp().$dayjs(date).format(format)
	})

	nuxtApp.provide(
		'downloadFile',
		async (fileUrl: string | Blob, fileName: string, directory: string = 'Documents') => {
			const blob = typeof fileUrl === 'string' ? await fetch(fileUrl).then(r => r.blob()) : fileUrl

			if (Capacitor.getPlatform() === 'web') {
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = fileName
				link.click()
				link.remove()
			} else {
				const { Filesystem, Directory } = await import('@capacitor/filesystem')
				const reader = new FileReader()

				reader.readAsDataURL(blob)

				await new Promise<void>((resolve, reject) => {
					reader.onloadend = () => {
						console.log('Downloading...', reader.result)

						Filesystem.writeFile({
							path: `reality-studio-${v4()}-${fileName}`,
							data: reader.result as string,
							directory: Directory[directory as keyof typeof Directory],
							recursive: true,
						})
							.then(() => resolve())
							.catch(reject)
					}
				})

				console.log('Download complete.')
			}
		}
	)

	nuxtApp.provide('takePicture', async () => {
		if (Capacitor.getPlatform() !== 'web') {
			console.log('Taking picture with Capacitor')
			try {
				const { Camera, CameraResultType } = await import('@capacitor/camera')
				const image = await Camera.getPhoto({
					quality: 90,
					allowEditing: false,
					resultType: CameraResultType.DataUrl,
				})

				return image.dataUrl
			} catch (error) {
				$sentryCaptureException(error)
			}
			return null
		}
	})
})
