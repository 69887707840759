import { defineStore } from "pinia";
import type { IUser } from "~/types/models/user.interface";

export const useUserStore = defineStore("user", {
  state: () =>
    ({
      user: null,
    } as { user: IUser | null }),
  getters: {
    isLoggedIn: (state) => !!state.user,
  },
  actions: {
    login(user: IUser) {
      this.user = user;
    },
    logout() {
      this.user = null;
    },
    setUser(user: IUser) {
      this.user = user;
    },
  },
});
