// exposing it to the components to make it less verbose on the project
// needs to import nuxt app every time.

import { useI18n } from 'vue-i18n'
import mitt from 'mitt'
import type { IUser } from '~/types/models/user.interface'

export const $t = function (key: string, namedParam?: any, plural?: any) {
	const { t } = useI18n()
	return t(key, namedParam, plural)
}

export const $i18n = () => {
	return useI18n()
}

export const $getPlatform = () => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$getPlatform()
}

export const $formatCurrency = (value: any) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$formatCurrency(value)
}

export const $getWindow = () => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$getWindow()
}

export const $copyToClipboard = (text: string) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$copyToClipboard(text)
}

export const $truncateText = (string: string, length = 0) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$truncateText(string, length)
}

export const $share = (content: any) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$share(content)
}

export const $getLocale = async () => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$getLocale()
}

export const $toast = async (options: any) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$toast(options)
}

export const $captureEvent = async (event: string, metadata = {}) => {
	const { $posthog } = useNuxtApp()

	return $posthog()?.capture(event, metadata)
}

export const $api = async <T>(url: string, options?: any): Promise<T> => {
	return useNuxtApp().$api(url, options)
}

export const $downloadFile = async (url: string, filename: string) => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$downloadFile(url, filename)
}

export const $takePicture = async () => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$takePicture()
}

export const $formatDate = (date: any, format = 'DD/MM/YYYY [at] HH:mm') => {
	const nuxtApp: any = useNuxtApp()
	return nuxtApp.$formatDate(date, format)
}
export const $mitt = mitt()
export const $dayjs = (...arg: any) => useNuxtApp().$dayjs(...arg)
export const $sentryCaptureException = (...args: any) => {
	return useNuxtApp().$sentryCaptureException(...args)
}

export const $identifyUser = (user: IUser) => useNuxtApp().$identifyUser(user)
