import { createI18n } from "vue-i18n";
import ptBR from "../locales/pt-BR.json";
import enUS from "../locales/en-US.json";
import esES from "../locales/es-ES.json";
export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: "en-US",
    fallbackLocale: "en-US",
    messages: {
      "en-US": enUS,
      "pt-BR": ptBR,
      "es-ES": esES,
    },
  });

  vueApp.use(i18n);
});
