import posthog from 'posthog-js'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtPlugin(nuxtApp => {
	const runtimeConfig = useRuntimeConfig()

	const posthogClient = runtimeConfig.public.enablePosthog
		? posthog.init('phc_p8lVEVPVi1aVBdIbIXiJByplsOR3QXPMS8PqgTj0Gth', {
				api_host: 'https://app.posthog.com',
				person_profiles: 'identified_only',
				capture_pageview: false, // we add manual pageview capturing below
				loaded: posthog => {
					if (import.meta.env.MODE === 'development') posthog.debug()
				},
		  })
		: null

	const router = useRouter()
	router.afterEach(to => {
		nextTick(() => {
			posthog.capture('$pageview', {
				current_url: to.fullPath,
			})
		})
	})

	nuxtApp.provide('posthog', () => posthogClient)
	nuxtApp.provide('identifyUser', async (user: IUser) => {
		const { Capacitor } = await import('@capacitor/core')

		const userData: any = {
			email: user.email,
			name: user.userName,
			hasSubscription: user.hasSubscription,
			subscription: user.plan && user.plan.name['en-US'],
			subscriptionCycle: user.plan && user.plan.billingCycle.unit,
			createdAt: user.createdAt,
			platform: Capacitor.getPlatform(),
			points: user.points,
			role: user.role,
		}

		if (userData.platform !== 'web') {
			const { App } = await import('@capacitor/app')
			const { version: appVersion } = await App.getInfo()

			userData.appVersion = appVersion
		}

		posthogClient?.identify(user._id, userData)
	})
	// return {
	//   provide: {
	//     posthog: () => posthogClient,
	//   },
	// };
})
